import React from 'react';
import './Footer.css';

const Footer = (props) => {
  const { label, year } = props.copyright;
  return (
    <footer className="site-footer clearfix">
      <section className="copyright">
        {label} &copy; {year || new Date().getFullYear()}
      </section>
    </footer>
  );
};

export default Footer;
