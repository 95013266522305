import React from 'react';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './MainHeader.css';

class MainHeader extends React.Component {
  render() {
    const { children, cover } = this.props;
    const classes = classNames('main-header', this.props.className, {
      'no-cover': !cover,
    });

    const coverImage = getImage(cover);

    return (
      <header className={classes} style={{ position: 'relative' }}>
        {coverImage && (
          <GatsbyImage
            alt="Cover image"
            image={coverImage}
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        )}
        {children}
      </header>
    );
  }
}

export default MainHeader;
